import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { Flex } from '@rebass/emotion';
import CmsPage from '../components/Elements/CmsPage';
import PageBlocksRenderer from '../components/PageBlocksRenderer';
import { PageProvider } from '../context/PageProvider';

const App = styled(Flex)`
  flex-direction: column;
  min-height: 100vh;
  padding: 0%;
  max-width: 100vw;
  font-family: ${props => props.theme.fonts.sans};
`;

const Page = ({ pageContext, data }) => {
  pageContext = { ...pageContext, ...data.sanityPage };

  useEffect(() => {
    if (data?.sanityPage?.slug?.current === 'home') {
      typeof window !== 'undefined' &&
        window.gtag &&
        window.gtag('event', 'conversion', {
          send_to: 'AW-299192274/zkAdCMfL2f0CENKf1Y4B',
        });
    }
  }, []);

  return (
    <CmsPage pageContext={pageContext}>
      <PageProvider page={pageContext}>
        <App>
          <PageBlocksRenderer container={pageContext} />
        </App>
      </PageProvider>
    </CmsPage>
  );
};

export default Page;

export const query = graphql`
  query PageQuery($pageId: String) {
    sanityPage(_id: { eq: $pageId }) {
      _id
      title
      description
      _type
      slug {
        _key
        _type
        current
      }
      content: _rawContent(resolveReferences: { maxDepth: 20 })
    }
  }
`;
